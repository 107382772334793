<template>
  <div class="data-generate virtual-face">
    <div class="generate-content">
      <div class="generate-area" v-loading="btnDisabled">
        <div class="img-list">
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <el-image :src="item" lazy fit="contain">
              <div slot="placeholder" v-loading="true" class="loading"></div>
            </el-image>
            <!-- <p class="tip">— 图片由AI技术生成 —</p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <el-button type="primary" @click="onCreate" :disabled="btnDisabled">生成下一批</el-button>
      <el-button type="primary" @click="onExport" :disabled="btnDisabled || exportLoading">
       <span class="btn-inner">
          <img :src="(btnDisabled || exportLoading) ? iconExportDisabled : iconExport" alt="" width="20" height="20" class="icon-export">
          <span>一键导出</span>
       </span>
    </el-button>
    </div>
  </div>
</template>

<script>
import store, {dispatch, ACTIONS, state, TYPE, commit} from '@/store';
import { Loading } from 'element-ui';
import { STORAGE } from '@/utils/const';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

export default {
  name: "virtual-face",
  components: {
  },
  data() {
    return {
      iconExport: require('@/assets/img/icon-export-white.png'),
      iconExportDisabled: require('@/assets/img/icon-export-disabled.png'),
      exportLoading: false,
    }
  },

  mounted() {
    // 若存在不生成新的
    if ((!this.dataList || !this.dataList.length) && !this.btnDisabled) {
      this.onCreate();
    }
  },

  computed: {
    dataList() {
      return state.faceCreateData ? state.faceCreateData.fileList : [];
    },

    btnDisabled() {
      return state.faceCreateData.loading;
    }
  },

  methods: {
    onCreate() {
      dispatch(ACTIONS.faceCreate);
    },

    // 导出
    onExport() {
      this.exportLoading = true
      let {dataList = []} = this;
      let zip = new JSZip();
      let imgFolder = zip.folder("images");
      let _index = 0;
      dataList.map((data, index) => {
        this.getBase64(data).then(base64 => {
          base64 = base64.split('base64,')[1];
          imgFolder.file(`downloadimage${index + 1}.png`, base64, { base64: true });
          if (_index == dataList.length - 1) {
            zip.generateAsync({ type: "blob" }).then((blob) => {
              saveAs(blob, "downloadimage.zip");
              this.exportLoading = false;
            })
          }
          _index++;
        });
      })
    },

    getBase64(img){
      let image = new Image();
      image.crossOrigin = '*'; //解决图片跨域问题
      image.src = img;
      return new Promise((resolve,reject) => {
        image.onload = ()=>{
          resolve(this.getBase64Image(image));
        }
      }) 
    },

    getBase64Image(img,width,height) {
      let canvas = document.createElement("canvas");
      canvas.width = width ? width : img.width;
      canvas.height = height ? height : img.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      let dataURL = canvas.toDataURL();
      return dataURL;	
    },
  }
};
</script>

<style lang="less">
</style>
